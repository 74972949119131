import { Auth0Provider } from "@auth0/auth0-react";
import React from "react";
import { useNavigate } from "react-router-dom";

export const Auth0ProviderWithNavigate = ({ children }) => {
  const navigate = useNavigate();

  const domain =
    process.env.NODE_ENV === "production"
      ? "jobcrawls.eu.auth0.com"
      : "devjobcrawls.eu.auth0.com";
  // TODO:: Make a development instance as well, so we're not always using prod login
  const clientId =
    process.env.NODE_ENV === "production"
      ? "NMNYNUSqhL4QF57m1ROKxRfKeCfcZgId"
      : "164ND2FkmoLw9quY9ulNvNbFGsIY0uEv";
  // TODO:: Change the callback depending on the language of the user
  const redirectUri =
    process.env.NODE_ENV === "production"
      ? "https://jobcrawls.com/en/callback"
      : "http://localhost:3000/en/callback";

  const onRedirectCallback = (appState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  if (!(domain && clientId && redirectUri)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: redirectUri,
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

import SearchModal from "../components/components/modals/SearchModal";
import RentModal from "../components/components/modals/RentModal";
import ToasterProvider from "../components/providers/ToasterProvider";
// import "./globals.css";
import ClientOnly from "../components/components/ClientOnly";
import CategoryBoxModal from "../components/components/modals/CategoryBoxModal";

export default function RootLayout({
  children,
  withCategories,
}: {
  children: React.ReactNode;
  withCategories?: boolean;
}) {
  return (
    <>
      <ClientOnly>
        <ToasterProvider />
        <CategoryBoxModal />
        <SearchModal />
        <RentModal />
      </ClientOnly>
      {children}
    </>
  );
}

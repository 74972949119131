import * as React from "react";
import Header from "../components/components/Header";
import Footer from "../components/components/Footer";
import { IntlShape, useIntl } from "react-intl";

export default function About() {
  const intl: IntlShape = useIntl();
  return (
    <div className="h-screen">
      <Header intl={intl} />
      <main>
        <section className="flex flex-col lg:flex-row items-start items-center lg:justify-center w-full w-full lg:px-10 py-12 ">
          <article className="w-4/5 lg:w-custom mb-10 lg:px-4 px-6 py-10 text-center text-primary-dark rounded-lg">
            <h2 className="pb-4 flex justify-center font-bold border-b border-gray-300">
              <span className="text-4xl">
                An Ethical Real Estate Marketplace
              </span>
            </h2>
            <ul className="text-sm font-bold">
              <li className="pt-4 pb-4">
                Help you reduce your impact on the environment
              </li>
              <li className="pt-3 pb-4">Bringing costs down for everybody</li>
              <li className="pt-4 pb-4">Not Profit-Driven</li>
            </ul>
          </article>
        </section>
      </main>
      <Footer />
    </div>
  );
}

import * as React from "react";
import { IntlShape, useIntl } from "react-intl";
import Profile from "../components/components/Profile";
import { useAuth0 } from "@auth0/auth0-react";
import RootLayout from "./layout";
import MobileNav from "../components/components/navbar/MobileNav";

export default function ProfileOverview() {
  const intl: IntlShape = useIntl();
  const { user } = useAuth0();
  return (
    <RootLayout withCategories={false}>
      <div className="h-[calc(100vh-200px)]">
        <main>
          <Profile />
        </main>
      </div>
      <MobileNav />
    </RootLayout>
  );
}

import * as React from "react";
import { useParams } from "react-router-dom";
import RootLayout from "./layout";
import ListingPage from "./listings/[listingId]/page";
import MobileNav from "../components/components/navbar/MobileNav";

export default function Listing() {
  const params = useParams();

  return (
    <>
      <RootLayout withCategories={false}>
        <ListingPage params={params} />
      </RootLayout>
      <MobileNav />
    </>
  );
}

import React from "react";
import { useLocation, Location } from "react-router-dom";
import { IntlProvider } from "react-intl";
import en from "./languages/compiled/en.json";
import fi from "./languages/compiled/fi.json";
import sv from "./languages/compiled/sv.json";
import RoutesCenter from "./routes/RoutesCenter";
import * as Sentry from "@sentry/react";
import { HelmetProvider } from "react-helmet-async";

function loadLocaleData(locale: string): any {
  switch (locale) {
    case "fi":
      return fi;
    case "sv":
      return sv;
    default:
      return en;
  }
}

Sentry.init({
  dsn: "https://f433b489c9378cd799e2405b4052bada@o4508105967140864.ingest.de.sentry.io/4508105968844880",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost" /* /^https:\/\/yourserver\.io\/api/ */],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

function App() {
  const location: Location = useLocation();
  let lang: string = location.pathname.substring(1, 3);
  lang = lang === "fi" ? lang : lang === "sv" ? lang : "en";
  const messages: any = loadLocaleData(lang);

  return (
    <IntlProvider messages={messages} locale={lang} defaultLocale="en">
      <HelmetProvider>
        <div className="App">
          <RoutesCenter />
        </div>
      </HelmetProvider>
    </IntlProvider>
  );
}

export default App;

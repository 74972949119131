import { useCallback, useMemo } from "react";
import { format } from "date-fns";
import HeartButton from "../HeartButton";
import Button from "../Button";
import { User } from "@auth0/auth0-spa-js";
import { useNavigate } from "react-router-dom";
import { localizedRouteGenerator } from "../../../languages/routes/routeGenerator";
import { useIntl } from "react-intl";
import { BiEuro } from "react-icons/bi";

interface ListingCardProps {
  listingId: string;
  data: any;
  reservation?: any;
  onAction?: (id: string) => void;
  disabled?: boolean;
  actionLabel?: string;
  actionId?: string;
  currentUser?: User | null;
}

const ListingCard: React.FC<ListingCardProps> = ({
  listingId,
  data,
  reservation,
  onAction,
  disabled,
  actionLabel,
  actionId = "",
  currentUser,
}) => {
  const navigate = useNavigate();
  const intl = useIntl();
  // const { getByValue } = useCountries()

  // const location = getByValue(data.locationValue)

  const handleCancel = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();

      if (disabled) {
        return;
      }

      onAction?.(actionId);
    },
    [disabled, onAction, actionId]
  );

  const price = useMemo(() => {
    if (reservation) {
      return reservation.totalPrice;
    }

    return data.price;
  }, [reservation, data.price]);

  const reservationDate = useMemo(() => {
    if (!reservation) {
      return null;
    }

    const start = new Date(reservation.startDate);
    const end = new Date(reservation.endDate);

    return `${format(start, "PP")} - ${format(end, "PP")}`;
  }, [reservation]);

  return (
    <div
      onClick={() =>
        navigate(localizedRouteGenerator(intl, "route.listing", listingId))
      }
      className="col-span-1 cursor-pointer group"
    >
      <div className="flex flex-col gap-2 w-full">
        <div
          className="
            aspect-square
            w-full
            relative
            overflow-hidden
            rounded-xl
          "
        >
          <img
            className="
              object-cover
              h-full
              w-full
              group-hover:scale-110
              transition
            "
            src={
              data.images.length && data.images[0]
                ? `https://jobmarketplace-imagebucket97210811-mnowgoqvcwya.s3.eu-central-1.amazonaws.com/${data.images[0]}`
                : "/images/default_home.png"
            }
            alt="Listing"
          />
          <div
            className="
            absolute
            top-3
            right-3
          "
          >
            <HeartButton listingId={data.id} currentUser={currentUser} />
          </div>
        </div>
        <div className="font-semibold text-lg">{data.location}</div>
        <div className="font-light text-neutral-500">
          {reservationDate || data.category}
        </div>
        <div className="flex flex-row items-center gap-1 font-semibold">
          {data.monthly_price}
          <BiEuro />
          /month
        </div>
        {onAction && actionLabel && (
          <Button
            disabled={disabled}
            small
            label={actionLabel}
            onClick={handleCancel}
          />
        )}
      </div>
    </div>
  );
};

export default ListingCard;

import { SearchCodeFeature } from "../../types";
import { FaTrainSubway } from "react-icons/fa6";
import { IoTrain } from "react-icons/io5";
import { MdLocalMall, MdOutlineTram } from "react-icons/md";
import * as React from "react";
import { capitalize } from "../../functions/string-manipulation";

interface SearchResultsListItemProps {
  geoItem: SearchCodeFeature;
  onClick: any;
  highlighted: boolean;
}

const SearchResultsListItem: React.FC<SearchResultsListItemProps> = ({
  geoItem,
  onClick,
  highlighted,
}) => {
  return (
    <li
      className={`pb-3 sm:pb-4 cursor-pointer ${
        highlighted ? " bg-blue-100" : ""
      }`}
      onClick={onClick}
    >
      <div className="flex items-center space-x-4 rtl:space-x-reverse">
        <div className="flex-1 min-w-0 p-2  ">
          <p
            className={`text-sm font-medium text-gray-900 truncate dark:text-white`}
          >
            {capitalize(geoItem.properties.name)}
          </p>
          <p className="text-sm text-gray-500 truncate dark:text-gray-400 flex">
            {geoItem.properties.has_metro ? (
              <span className="flex">
                <FaTrainSubway className="mr-1 mt-1" />
                Metro
              </span>
            ) : (
              ""
            )}
            {geoItem.properties.has_commuter_train ? (
              <span className="flex">
                <IoTrain className="mr-1 mt-1" />
                Train
              </span>
            ) : (
              ""
            )}
            {geoItem.properties.has_tram ? (
              <span className="flex">
                <MdOutlineTram className="mr-1 mt-1" />
                Tram
              </span>
            ) : (
              ""
            )}
          </p>
        </div>
        <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white p-2">
          {geoItem.properties.has_mall ? (
            <>
              <MdLocalMall />
              {capitalize(geoItem.properties.has_mall)}
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </li>
  );
};

export default SearchResultsListItem;

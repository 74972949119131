import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { localizedRouteGenerator } from "../../languages/routes/routeGenerator";
import LoginButton from "./LoginButton";
import LogoutButton from "./LogoutButton";
import { useAuth0 } from "@auth0/auth0-react";

export default function Header(props) {
  const intl = props.intl;
  const [expanded, setExpanded] = useState(false);
  const [expandedLangDropdown, setExpandedLangDropdown] = useState(false);
  const { isAuthenticated, isLoading } = useAuth0();

  return (
    <header>
      <nav className="flex items-center justify-between flex-wrap bg-blue-500 p-6">
        <Link to={localizedRouteGenerator(intl, "route.home")}>
          <div className="flex items-center flex-shrink-0 text-white mr-6">
            <img
              src="/green-house.png"
              className="object-scale-down h-10 w-10"
            />
            <span className="font-semibold text-xl tracking-tight">
              Jobmarketplace
            </span>
          </div>
        </Link>
        <div className="block lg:hidden">
          <button
            onClick={() => setExpanded(!expanded)}
            className="flex items-center px-3 py-2 border rounded text-blue-200 border-blue-400 hover:text-white hover:border-white"
          >
            <svg
              className="fill-current h-3 w-3"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
        </div>
        <div
          className={`${
            expanded ? "hidden lg:block" : ""
          } w-full block flex-grow lg:flex lg:items-center lg:w-auto`}
        >
          <div className="text-sm lg:flex-grow">
            <Link
              to={localizedRouteGenerator(intl, "route.search")}
              className="block mt-4 lg:inline-block lg:mt-0 text-blue-200 hover:text-white mr-4"
            >
              <FormattedMessage
                id="header.title.search"
                description="The search title in the header"
                defaultMessage="Search"
              />
            </Link>
            <Link
              to={localizedRouteGenerator(intl, "route.insertion")}
              className="block mt-4 lg:inline-block lg:mt-0 text-blue-200 hover:text-white mr-4"
            >
              <FormattedMessage
                id="header.title.newListing"
                description="The place a listing title in the header"
                defaultMessage="Place a Listing"
              />
            </Link>
            <Link
              to={localizedRouteGenerator(intl, "route.about")}
              className="block mt-4 lg:inline-block lg:mt-0 text-blue-200 hover:text-white"
            >
              <FormattedMessage
                id="header.title.about"
                description="The about title in the header"
                defaultMessage="About us"
              />
            </Link>
            <Link
              to={localizedRouteGenerator(intl, "route.messenger")}
              className="ml-2 block mt-4 lg:inline-block lg:mt-0 text-blue-200 hover:text-white"
            >
              <FormattedMessage
                id="header.title.messages"
                description="Messages"
                defaultMessage="Messages"
              />
            </Link>
          </div>
          <div className="hidden w-full md:block md:w-auto" id="mobile-menu">
            <ul className="flex flex-col mt-4 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium">
              <li>
                {isLoading ? (
                  <div>Loading...</div>
                ) : isAuthenticated ? (
                  <LogoutButton />
                ) : (
                  <LoginButton />
                )}
              </li>
              {isAuthenticated ? (
                <li>
                  <Link
                    to={localizedRouteGenerator(intl, "route.profileOverview")}
                    className="block mt-4 lg:inline-block lg:mt-0 text-blue-200 hover:text-white"
                  >
                    <FormattedMessage
                      id="header.title.profile"
                      description="Link to the profile page from the header"
                      defaultMessage="My Profile"
                    />
                  </Link>
                </li>
              ) : null}
              <li>
                <button
                  id="dropdownNavbarButton"
                  onClick={() => setExpandedLangDropdown(!expandedLangDropdown)}
                  className="flex justify-between items-center py-2 pr-4 pl-3 w-full font-medium text-white border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 md:w-auto dark:text-gray-400 dark:hover:text-white dark:focus:text-white dark:border-gray-700 dark:hover:bg-gray-700 md:dark:hover:bg-transparent"
                >
                  {intl.locale === "fi"
                    ? "Suomi"
                    : intl.locale === "sv"
                    ? "Svenska"
                    : "English"}{" "}
                  <svg
                    className="ml-1 w-4 h-4"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </button>
                <div
                  id="dropdownNavbar"
                  className={`${
                    !expandedLangDropdown ? "hidden " : ""
                  }z-10 w-44 bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700 dark:divide-gray-600`}
                >
                  <ul
                    className="py-1 text-sm text-gray-700 dark:text-gray-400"
                    aria-labelledby="dropdownNavbarButton"
                  >
                    <li>
                      <Link
                        to="/fi"
                        className="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        FI
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/sv"
                        className="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        SV
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/en"
                        className="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        EN
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}

import { Listing } from "../types";

export const createEmptyListing = (): Listing => {
  return {
    user_name: null,
    user_email: null,
    location: "",
    rental_type: "",
    housing_type: "",
    accommodation_type: "",
    floor: "",
    vacancy: "",
    lease: "",
    housing_description: "",
    number_of_rooms: "",
    construction_year: "",
    living_area: "",
    other_area: "",
    total_area: "",
    plot_area: "",
    features: "",
    condition: "",
    more_information: "",
    special_terms: "",
    monthly_price: null,
    right_of_occupancy_fee: "",
    payment_day: "",
    deposit: "",
    water: "",
    electricity: "",
    parking: null,
    own_sauna: null,
    fireplace: null,
    common_sauna: null,
    elevator: null,
    balcony: null,
    furnished: null,
    description: "",
    title: "",
    costs_information: "",
    pets_allowed: null,
    smoking_allowed: null,
    car_info: "",
    rental_general: "",
    yard_description: "",
    other_rental_properties: "",
    communications: "",
    ventilation: "",
    heating: "",
    renovations: "",
    further_information: "",
    rental_energy_class: "",
    material_kitchen_floor: "",
    material_kitchen_wall: "",
    material_livingroom_floor: "",
    material_livingroom_wall: "",
    construction_material: "",
    material_description: "",
    bathroom_features: "",
    bathroom_toilet_count: "",
    bathroom_materials_equipment: "",
    kitchen_type: "",
    kitchen_features: "",
    kitchen_stove: "",
    kitchen_refrigerator: "",
    kitchen_materials_equipment: "",
    housing_company_name: "",
    housing_company_manager: "",
    housing_maintenance: "",
    housing_features: "",
    housing_past_renovations: "",
    housing_future_renovations: "",
    housing_info: "",
    housing_energy_class: "",
    longitude: 0,
    latitude: 0,
    images: [],
  };
};

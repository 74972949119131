import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { IntlShape, useIntl } from "react-intl";
import Header from "../components/components/Header";
import Footer from "../components/components/Footer";

export default function AuthCallback() {
  const { error } = useAuth0();

  if (error) {
    return (
      <div className="h-screen">
        {/*<Header intl={intl} />*/}
        <main>
          <span>{error.message}</span>
        </main>
        {/*<Footer />*/}
      </div>
    );
  }
  return (
    <div className="h-screen">
      {/*<Header intl={intl} />*/}
      <main>
        <div> Successful Authentication </div>
      </main>
      {/*<Footer />*/}
    </div>
  );
}

import React from "react";
import Home from "./Home";
import { Routes, Route } from "react-router-dom";
import Business from "./Business";
import About from "./About";
import Listing from "./Listing";
import { defineMessages, useIntl } from "react-intl";
import NoLang from "./NoLang";
import { localizedRouteGenerator } from "../languages/routes/routeGenerator";
import ProfileOverview from "./ProfileOverview";
import AuthCallback from "./AuthCallback";
import Properties from "./Properties";
import CoolFinnishTechCompaniesEnglish from "./Blog/CoolFinnishTechCompaniesEnglish";
import Blog from "./Blog";

const routeMessages = defineMessages({
  homeRoute: {
    id: "route.home",
    description: "Home page route",
    defaultMessage: "/",
  },
  listingRoute: {
    id: "route.listing",
    description: "About listing route",
    defaultMessage: "/listing",
  },
  propertiesRoute: {
    id: "route.properties",
    description: "Where the user can view their own listings",
    defaultMessage: "/properties",
  },
  favouritesRoute: {
    id: "route.favourites",
    description: "Where users can view their favourited listings",
    defaultMessage: "/favourites",
  },
  savedSearchesRoute: {
    id: "route.savedSearches",
    description: "Where users can view their previous searches",
    defaultMessage: "/saved-searches",
  },
  businessRoute: {
    id: "route.business",
    description: "Business page route",
    defaultMessage: "/business",
  },
  aboutRoute: {
    id: "route.about",
    description: "About page route",
    defaultMessage: "/about",
  },
  profileOverviewRoute: {
    id: "route.profileOverview",
    description: "All about the logged-in tray",
    defaultMessage: "/user-profile-overview",
  },
  authCallbackRoute: {
    id: "route.authCallback",
    description: "Where the authentication returns to after",
    defaultMessage: "/callback",
  },
  // TODO:: Cleanup messenger URLs to make more sense
  // TODO:: Actually implement correct routes for the mobile versions of the messenger
  messengerRoute: {
    id: "route.messenger",
    description: "Where you can access the messenger",
    defaultMessage: "/messages",
  },
  channelsRoute: {
    id: "route.channels",
    description: "Where you can access the messenger specific channels",
    defaultMessage: "/messages/channels",
  },
  channelSearchRoute: {
    id: "route.channelSettings",
    description: "Where you can search the messenger channels",
    defaultMessage: "/messages/channels/settings",
  },
  channelSettings: {
    id: "route.channelSearch",
    description: "Where you can access the messenger channel settings",
    defaultMessage: "/messages/channels/search",
  },
  coolFinnishTechCompaniesEnglishRoute: {
    id: "route.coolFinnishTechCompaniesEnglish",
    description:
      "An article about cool Finnish tech companies who work in English",
    defaultMessage: "/blog/cool-finnish-tech-companies-english",
  },
  blog: {
    id: "route.blog",
    description: "An index of all blog articles",
    defaultMessage: "/blog",
  },
});

function RoutesCenter() {
  const intl = useIntl();
  return (
    <Routes>
      <Route path="*" element={<NoLang />} />
      <Route
        path={localizedRouteGenerator(intl, "route.home")}
        element={<Home />}
      />
      <Route
        path={localizedRouteGenerator(intl, "route.properties")}
        element={<Properties />}
      />
      <Route
        path={localizedRouteGenerator(intl, "route.favourites")}
        element={<Home />}
      />
      <Route
        path={localizedRouteGenerator(intl, "route.business")}
        element={<Business />}
      />
      <Route
        path={localizedRouteGenerator(intl, "route.about")}
        element={<About />}
      />
      <Route
        path={localizedRouteGenerator(
          intl,
          "route.coolFinnishTechCompaniesEnglish"
        )}
        element={<CoolFinnishTechCompaniesEnglish />}
      />
      <Route
        path={localizedRouteGenerator(intl, "route.blog")}
        element={<Blog />}
      />
      <Route
        path={localizedRouteGenerator(intl, "route.listing", ":listingId")}
        element={<Listing />}
      />
      <Route
        path={localizedRouteGenerator(intl, "route.profileOverview")}
        element={<ProfileOverview />}
      />
      <Route
        path={localizedRouteGenerator(intl, "route.authCallback")}
        element={<AuthCallback />}
      />
    </Routes>
  );
}

export default RoutesCenter;

import * as React from "react";
import { InstantSearch2 } from "../components/InstantSearch2";

export default function Home() {
  return (
    <>
      {/*<RootLayout withCategories={false}>*/}
      <InstantSearch2 />
      {/*</RootLayout>*/}
    </>
  );
}

import { Highlight } from "react-instantsearch";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link, useLocation } from "react-router-dom";
import { AiOutlineArrowRight } from "react-icons/ai";
import ReactGA from "react-ga4";

const sharePage = (url: string) => {
  if (navigator.share) {
    navigator
      .share({
        title: "jobcrawls.com | The Easy Job Marketplace",
        url,
      })
      .then(() => {
        console.log("Thanks for sharing!");
      })
      .catch(console.error);
  } else {
    console.log("This browser does not support sharing");
    //TODO:: Add fallback for browsers that do not support sharing with copy to clipboard + toast
  }
};

export const Hit = ({ hit, sendEvent }) => {
  const location = useLocation();
  const intl = useIntl();
  const logoBackgroundColour = [
    "Supercell",
    "Unity Technologies",
    "Aidon Oy",
    "IQM Quantum Computers",
    "Basemark",
    "Polar Squad",
  ].includes(hit.company.name)
    ? "bg-black dark:bg-transparent"
    : "bg-white dark:bg-gray-700";

  return (
    <article className="bg-white dark:bg-gray-700 shadow-md">
      {/*TODO:: Add back per card shadow when we can remove the larger card thing that divides results */}
      <div className="w-full rounded-2xl bg-white dark:bg-gray-700 text-black dark:text-gray-200 p-4 ">
        <div className="grid grid-cols-5 md:grid-cols-12 m-2">
          <div className="col-span-1">
            <img
              className={`${logoBackgroundColour} dark:bg-gray-700"`}
              height="100"
              width="100"
              alt="Avatar"
              src={hit.company.logoUrl || "/www.png"}
            />
          </div>
          <div className="col-span-2 md:col-span-4 inline-flex items-center space-x-4 w-full">
            <div className="ml-4">
              <div className="hit-company.name">
                <Link
                  className="font-medium text-blue-600 dark:text-blue-500 hover:underline text-lg"
                  to={hit.company ? hit.url : "#"}
                >
                  <Highlight attribute="companyId" hit={hit} />
                </Link>
              </div>
              <div className="text-nowrap italic">
                <Highlight attribute="company.industry" hit={hit} />
              </div>
            </div>
          </div>
          <div className="col-span-2 md:col-span-7 flex flex-row justify-end content-end space-x-2 w-full">
            {/* TODO:: Add functionality for save button */}
            <button
              onClick={() => {
                ReactGA.event({
                  category: "interactions",
                  action: "clickSaveAd",
                  nonInteraction: false, // optional, true/false
                });
                sharePage(
                  "https://jobcrawls.com" + location.pathname + location.search
                );
              }}
              className="flex items-center bg-transparent hover:bg-amber-300 text-black-700 font-semibold pl-3 md:pl-2 pr-2 border border-black dark:border-white hover:border-transparent rounded h-10"
            >
              <img
                alt="save"
                src="/star.png"
                className="object-scale-down h-3 w-3 mr-1"
              />
              <p className="hidden md:block">
                {intl.formatMessage({
                  id: "hit.save",
                  defaultMessage: "Save",
                  description: "Save button on job card",
                })}
              </p>
            </button>
            <button
              onClick={() => {
                ReactGA.event({
                  category: "interactions",
                  action: "clickShareAd",
                  nonInteraction: false, // optional, true/false
                });
                sharePage(
                  "https://jobcrawls.com" + location.pathname + location.search
                );
              }}
              className="flex items-center bg-transparent hover:bg-amber-300 text-black-700 font-semibold pl-3 md:pl-2 pr-2 border border-black dark:border-white hover:border-transparent rounded h-10"
            >
              <img
                alt="share"
                src="/share.png"
                className="object-scale-down h-3 w-3 mr-1"
              />
              <p className="hidden md:block">
                {intl.formatMessage({
                  id: "hit.share",
                  defaultMessage: "Share",
                  description: "Share button on job card",
                })}
              </p>
            </button>
          </div>
        </div>
        <hr />
        <br />
        <div className="grid grid-cols-1 lg:grid-cols-8">
          <div className="flex-col items-center space-x-2 col-span-3">
            <div className="">
              <div className="inline-flex items-center space-x-2">
                <div className="font-bold text-lg">
                  <Highlight attribute="normalisedTitle" hit={hit} />
                </div>
                <div className="text-nowrap">({hit.normalisedRoleLevel})</div>
              </div>
              <div className="py-1">
                <Highlight attribute="title" hit={hit} />
              </div>
              <div className="py-1">
                {!hit.salaryRange ||
                (hit.salaryRange &&
                  (!hit.salaryRange.min || !hit.salaryRange.max)) ? (
                  <div className="py-1 text-xs font-medium text-red-700">
                    <FormattedMessage
                      id="hit.salary_not_disclosed"
                      defaultMessage="Salary not disclosed"
                    />
                  </div>
                ) : (
                  <div>
                    {hit.salaryRange &&
                    hit.salaryRange.min !== hit.salaryRange.max ? (
                      <>
                        &euro; {intl.formatNumber(hit.salaryRange.min)}
                        &nbsp;-{" "}
                      </>
                    ) : null}
                    &euro; {intl.formatNumber(hit.salaryRange.max)}
                  </div>
                )}
              </div>
              <div className="flex-wrap inline-flex items-center">
                {hit.location &&
                  hit.location.map((jobLocation: string) => (
                    <div
                      key={jobLocation + hit.company.name}
                      className="cursor-pointer rounded-md bg-green-50 px-2 py-1 m-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20 mt-4"
                    >
                      {jobLocation}
                    </div>
                  ))}
              </div>
              <div className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 m-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                <Highlight attribute="remoteness" hit={hit} />
              </div>
              <div className="inline-flex items-center rounded-md bg-orange-50 px-2 py-1 m-1 text-xs font-medium text-orange-700 ring-1 ring-inset ring-orange-600/20">
                <Highlight attribute="jobType" hit={hit} />
              </div>
              <div className="inline-flex items-center rounded-md bg-purple-50 px-2 py-1 m-1 text-xs font-medium text-purple-700 ring-1 ring-inset ring-purple-600/20">
                {intl.formatDate(hit.postedDate)}
              </div>
              <div className="flex-wrap inline-flex items-center gap-2">
                {hit.requiredLanguages &&
                  hit.requiredLanguages.map((language: string) => (
                    <div
                      key={language}
                      className="cursor-pointer rounded-md gray px-2 py-1 text-xs font-medium text-gray-700 ring-1 ring-inset ring-gray-600/20 mt-4"
                    >
                      {language}
                    </div>
                  ))}
              </div>
              {/* TODO:: Add this back when we have shorter benefits cards */}
              {/*{!isMobile ? (*/}
              {/*  <>*/}
              {/*    <div className="flex-wrap inline-flex items-center gap-1 mt-4">*/}
              {/*      {hit.benefits &&*/}
              {/*        hit.benefits.map((benefit: string) => (*/}
              {/*          <div*/}
              {/*            key={benefit}*/}
              {/*            className="cursor-pointer rounded-md gray px-2 py-1 text-xs font-medium text-gray-700 ring-1 ring-inset ring-gray-600/20"*/}
              {/*          >*/}
              {/*            {benefit}*/}
              {/*          </div>*/}
              {/*        ))}*/}
              {/*    </div>*/}
              {/*  </>*/}
              {/*) : null}*/}
            </div>
          </div>
          {/* TODO: Bring this back when requirements are shortened */}
          <div className="hit-description col-span-5 divide-dashed">
            {/*<div className="flex-wrap inline-flex items-center gap-1 mt-4">*/}
            {/*  {hit.requirements &&*/}
            {/*    hit.requirements.map((requirements: string) => (*/}
            {/*      <div*/}
            {/*        key={requirements}*/}
            {/*        className="cursor-pointer rounded-md gray px-2 py-1 text-xs font-medium text-gray-700 ring-1 ring-inset ring-gray-600/20"*/}
            {/*      >*/}
            {/*        {requirements}*/}
            {/*      </div>*/}
            {/*    ))}*/}
            {/*</div>*/}
            {/*<br />*/}
            <Highlight attribute="description" hit={hit} />
            <div className="flex-wrap inline-flex items-center gap-2 pt-3">
              {hit.skillsAndTechnologies
                ? hit.skillsAndTechnologies.map((skill: string) => (
                    <div
                      key={skill}
                      className="cursor-pointer rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-600/20"
                    >
                      {skill}
                    </div>
                  ))
                : null}
            </div>
            <div className="mt-12 flex flex-row justify-end content-end space-x-2 w-full">
              <Link
                className="font-medium text-black dark:text-white hover:underline text-lg"
                to={hit.company ? hit.url : "#"}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => {
                  ReactGA.event({
                    category: "interactions",
                    action: "clickSeeAd",
                    nonInteraction: false, // optional, true/false
                  });
                  sendEvent("conversion", hit, "Job Ad Clicked");
                }}
              >
                <button className="flex items-center bg-transparent hover:bg-amber-300 text-black-700 font-semibold px-2 border border-black dark:border-white hover:border-transparent rounded h-10">
                  <p>
                    {intl.formatMessage({
                      id: "hit.seeAd",
                      defaultMessage: "See Ad",
                      description: "Link to the original job ad",
                    })}
                  </p>
                  <AiOutlineArrowRight className="ml-1" />
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* TODO:: Add a bottom collapsible section with more info we store like skills and stuff*/}
      {/* TODO:: Can we put the company background image faded behind? */}
      <br />
      <hr />
    </article>
  );
};

import { useEffect, useMemo, useState } from "react";
import { Range } from "react-date-range";
import { differenceInDays, eachDayOfInterval } from "date-fns";
import Container from "../../../components/components/Container";
import ListingHead from "../../../components/components/listings/ListingHead";
import ListingInfo from "../../../components/components/listings/ListingInfo";
import { User } from "@auth0/auth0-spa-js";
import { Listing } from "../../../types";

const initialDateRange = {
  startDate: new Date(),
  endDate: new Date(),
  key: "selection",
};

interface ListingClientProps {
  listingId: string;
  reservations?: any[];
  listing: Listing;
  currentUser?: User | null;
}

const extractNumberFromString = (unclearString: string): number => {
  return parseInt(unclearString.match(/\d/g).join(""));
};

const ListingClient: React.FC<ListingClientProps> = ({
  listingId,
  listing,
  reservations = [],
  currentUser,
}) => {
  const listingRent = listing.monthly_price;

  const disabledDates = useMemo(() => {
    let dates: Date[] = [];

    reservations.forEach((reservation: any) => {
      const range = eachDayOfInterval({
        start: new Date(reservation.startDate),
        end: new Date(reservation.endDate),
      });

      dates = [...dates, ...range];
    });

    return dates;
  }, [reservations]);

  const [isLoading, setIsLoading] = useState(false);
  const [totalPrice, setTotalPrice] = useState(listingRent);
  const [dateRange, setDateRange] = useState<Range>(initialDateRange);

  const onCreateReservation = () => {};

  // const onCreateReservation = useCallback(() => {
  //   if (!currentUser) {
  //     return loginModal.onOpen()
  //   }
  //   setIsLoading(true)
  //
  //   axios
  //     .post('/api/reservations', {
  //       totalPrice,
  //       startDate: dateRange.startDate,
  //       endDate: dateRange.endDate,
  //       listingId: listing?.id,
  //     })
  //     .then(() => {
  //       toast.success('Listing reserved!')
  //       setDateRange(initialDateRange)
  //       router.push('/trips')
  //     })
  //     .catch(() => {
  //       toast.error('Something went wrong.')
  //     })
  //     .finally(() => {
  //       setIsLoading(false)
  //     })
  // }, [totalPrice, dateRange, listing?.id, router, currentUser, loginModal])

  useEffect(() => {
    if (dateRange.startDate && dateRange.endDate) {
      const dayCount = differenceInDays(dateRange.endDate, dateRange.startDate);

      if (dayCount && listingRent) {
        setTotalPrice(dayCount * listingRent);
      } else {
        setTotalPrice(listingRent);
      }
    }
  }, [dateRange, listingRent]);

  return (
    <Container>
      <div
        className="
          max-w-screen-lg 
          mx-auto
          bg-white
          dark:bg-slate-600
          rounded-md
          shadow-lg
          p-8
        "
      >
        <div className="flex flex-col gap-6">
          <ListingHead
            title={listing.location}
            listing={listing}
            location={listing.location}
            id={listingId}
            currentUser={currentUser}
          />
          <div
            className="
              grid 
              grid-cols-1 
              md:grid-cols-7 
              md:gap-10 
              mt-6
            "
          >
            <ListingInfo
              user={currentUser}
              listingId={listingId}
              listing={listing}
            />
            {/*<div*/}
            {/*  className="*/}
            {/*    order-first */}
            {/*    mb-10 */}
            {/*    md:order-last */}
            {/*    md:col-span-3*/}
            {/*  "*/}
            {/*>*/}
            {/*  <ListingReservation*/}
            {/*    price={listingRent}*/}
            {/*    totalPrice={totalPrice}*/}
            {/*    onChangeDate={(value) => setDateRange(value)}*/}
            {/*    dateRange={dateRange}*/}
            {/*    onSubmit={onCreateReservation}*/}
            {/*    disabled={isLoading}*/}
            {/*    disabledDates={disabledDates}*/}
            {/*  />*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ListingClient;

import Container from "../components/components/Container";
import ListingCard from "../components/components/listings/ListingCard";
import EmptyState from "../components/components/EmptyState";

import ClientOnly from "../components/components/ClientOnly";
import { useAuth0 } from "@auth0/auth0-react";
import { ListingsObject } from "../types";

interface HomeProps {
  listings: ListingsObject;
  renderMap: boolean;
}

const RootListingsLayout = ({ listings, renderMap }: HomeProps) => {
  const { user } = useAuth0();

  if (Object.values(listings).length === 0) {
    return (
      <ClientOnly>
        <EmptyState showReset />
      </ClientOnly>
    );
  }

  return (
    <ClientOnly>
      <Container>
        <div
          className={`pt-24
            grid 
            grid-cols-1 
            ${
              renderMap
                ? "sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-3"
                : "sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6"
            }
            gap-8`}
        >
          {Object.entries(listings).map(([key, value]) => (
            <ListingCard
              listingId={key}
              currentUser={user}
              key={key}
              data={value}
            />
          ))}
        </div>
      </Container>
    </ClientOnly>
  );
};

export default RootListingsLayout;

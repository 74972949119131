import "instantsearch.css/themes/satellite.css";
import {
  Hits,
  Configure,
  Pagination,
  Stats,
  SortBy,
  Breadcrumb,
  ClearRefinements,
  useInstantSearch,
} from "react-instantsearch";

import { Hit } from "./Hit";
import * as React from "react";
import { SearchWrapper } from "../SearchWrapper";
import { searchTree } from "../searchTree";
import ReactGA from "react-ga4";
import { QuickSearchFacet } from "../QuickSearchFacet";
import { QuickSearchQuery } from "../QuickSearchQuery";
import { QuickSearchBanner } from "../QuickSearchBanner";
const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

export const InstantSearch2 = () => {
  return (
    <SearchWrapper>
      <div className="bg-white dark:bg-gray-700">
        <Configure hitsPerPage={25} />
        {/* TODO:: Setup bread crumbs to follow the job hierarchy so people can go back */}
        <Breadcrumb
          attributes={["categories.lvl0", "categories.lvl1", "categories.lvl2"]}
        />
        {/* TODO:: create sorts so people can sort by common sorts */}
        {/*<SortBy*/}
        {/*  items={[*/}
        {/*    { label: 'Featured', value: 'instant_search' },*/}
        {/*    { label: 'Price (asc)', value: 'instant_search_price_asc' },*/}
        {/*    { label: 'Price (desc)', value: 'instant_search_price_desc' },*/}
        {/*  ]}*/}
        {/*/>*/}
        <div className="w-full px-0 lg:px-12 bg-white dark:bg-gray-700 pt-32 xl:pt-16 m-auto max-w-sm md:max-w-xl lg:max-w-2xl xl:max-w-5xl">
          <QuickSearchBanner />
          <div className="ais-InstantSearch w-full bg-white dark:bg-gray-700 m-auto max-w-5xl">
            <div className="text-black dark:text-white pl-4">
              <Stats />
            </div>
            {/* TODO:: Change hit component class so it can show dark and light */}
            <Hits hitComponent={Hit} />
          </div>
          <div className="w-full pt-0 lg:pt-8 pb-8 items-center justify-center ml-0 md:ml-8 lg:ml-16 xl:ml-32">
            {/* TODO:: Find a way to center this */}
            <Pagination padding={isMobile ? 1 : 2} />
          </div>
        </div>
      </div>
    </SearchWrapper>
  );
};

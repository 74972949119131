import React, { useState, useEffect } from "react";
import { useRange } from "react-instantsearch";
import { FormattedMessage, useIntl } from "react-intl";

export function RangeSlider(props: any) {
  const { start, range, canRefine, refine } = useRange(props);
  const { min, max } = range;
  const [value, setValue] = useState(0);

  const intl = useIntl();

  const from: number = Math.max(
    min,
    Number.isFinite(start[0]) ? start[0] : min
  );
  const to: number = Math.min(max, Number.isFinite(start[1]) ? start[1] : max);

  const changeRangeValue = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.target as HTMLInputElement;
    setValue(parseInt(value));
    refine([parseInt(value), to]);
  };

  useEffect(() => {
    setValue(from);
  }, [from, to]);

  return (
    <>
      <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
        <FormattedMessage
          id="range-slider"
          defaultMessage={`Minimum Salary`}
          description={"Minimum Salary Range slider"}
        />
      </label>
      <input
        id="large-range"
        className="cursor-pointer"
        type="range"
        min={min}
        max={max}
        value={value}
        onChange={changeRangeValue}
        disabled={!canRefine}
        step={100}
      />
      <div className="w-full flex flex-row justify-between">
        <label className="text-sm text-gray-500 dark:text-gray-400">
          &euro;{intl.formatNumber(value)}
        </label>
        <label className="text-sm text-gray-500 dark:text-gray-400">
          &euro;{intl.formatNumber(to)}
        </label>
      </div>
    </>
  );
}

import * as React from "react";
import Header from "../components/components/Header";
import Footer from "../components/components/Footer";
import { IntlShape, useIntl } from "react-intl";

export default function Business() {
  const intl: IntlShape = useIntl();

  return (
    <div className="h-screen">
      <Header intl={intl} />
      <main>
        <section className="flex flex-col lg:flex-row items-start items-center lg:justify-center w-full w-full lg:px-10 py-12 ">
          <article className="w-4/5 lg:w-custom mb-10 lg:px-4 px-6 py-10 text-center text-primary-dark rounded-lg">
            <h5 className="font-bold text-base">Basic</h5>
            <h2 className="pb-4 flex justify-center font-bold border-b border-gray-300">
              <span className="text-6xl">Free</span>
            </h2>
            <ul className="text-sm font-bold">
              <li className="pt-4 pb-4 border-b border-gray-300">
                1 active listing
              </li>
              <li className="pt-3 pb-4 border-b border-gray-300">
                Basic listing appearance
              </li>
              <li className="pt-4 pb-4 border-b border-gray-300">
                Non-priority listing
              </li>
            </ul>
            <button className="uppercase text-center text-sm mt-12 xl:px-24 px-12 sm:px-16 py-2 font-bold text-primary-very-light rounded-md">
              Learn More
            </button>
          </article>
          <article className="lg:w-custom w-4/5 mb-10 px-6 py-16 lg:-mt-6 text-center rounded-lg">
            <h5 className="font-bold text-base ">Professional</h5>
            <h2 className="font-bold pb-4 mt-2 border-b border-gray-100 flex justify-center">
              <span className="text-3xl mt-6 mr-1">€</span>
              <span className="text-6xl "> 249.99</span>
              <span className="text-3xl mt-6 mr-1">month</span>
            </h2>
            <ul className=" text-sm font-bold">
              <li className="pt-4 pb-4 border-b border-gray-200">
                Unlimited active listings
              </li>
              <li className="pt-4 pb-4 border-b border-gray-200">
                Custom listing appearance
              </li>
              <li className="pt-4 pb-4 border-b border-gray-200">
                Ability to book toplisting
              </li>
            </ul>
            <button className="uppercase text-center text-sm mt-10 xl:px-24 px-12 sm:px-16 py-2 rounded-md font-bold bg-primary-very-light text-primary-blue">
              Learn More
            </button>
          </article>
          {/*<article className="w-4/5 lg:w-custom mb-10 lg:px-4 px-6 py-10 text-center text-primary-dark rounded-lg">*/}
          {/*  <h5 className="font-bold text-base">Master</h5>*/}
          {/*  <h2 className="flex justify-center pb-4 font-bold border-b border-gray-200">*/}
          {/*    <span className="text-3xl mt-6 mr-1">$</span>*/}
          {/*    <span className="text-6xl">399.99</span>*/}
          {/*  </h2>*/}
          {/*  <ul className="text-sm font-bold">*/}
          {/*    <li className="pt-4 pb-4 border-b border-gray-200">*/}
          {/*      2 TB Storage*/}
          {/*    </li>*/}
          {/*    <li className="pt-4 pb-4 border-b border-gray-200">*/}
          {/*      10 Users Allowed*/}
          {/*    </li>*/}
          {/*    <li className="pt-4 pb-4 border-b border-gray-200">*/}
          {/*      Send up to 20 GB*/}
          {/*    </li>*/}
          {/*  </ul>*/}
          {/*  <button className="uppercase text-center text-sm mt-12 xl:px-24 px-12 sm:px-16 py-2 rounded-md font-bold text-primary-very-light">*/}
          {/*    Learn More*/}
          {/*  </button>*/}
          {/*</article>*/}
        </section>
      </main>
      <Footer />
    </div>
  );
}

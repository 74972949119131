"use client";

import Heading from "../Heading";
import HeartButton from "../HeartButton";
import { User } from "@auth0/auth0-spa-js";
import { Carousel } from "react-responsive-carousel";

interface ListingHeadProps {
  title: string;
  location: string;
  listing: any;
  id: string;
  currentUser?: User | null;
}

const ListingHead: React.FC<ListingHeadProps> = ({
  title,
  location,
  listing,
  id,
  currentUser,
}) => {
  const listingImages =
    listing.images.length && listing.images[0]
      ? listing.images.map(
          (imgUrl) =>
            `https://jobmarketplace-imagebucket97210811-mnowgoqvcwya.s3.eu-central-1.amazonaws.com/${imgUrl}`
        )
      : ["/images/default_home.png"];

  return (
    <>
      <Heading title={title} subtitle={location} />
      <div
        className="
          w-full
          h-[60vh]
          overflow-hidden 
          rounded-xl
          relative
        "
      >
        {/*<img src={imageSrc} className="object-cover w-full" alt="Image" />*/}
        <Carousel
          className="object-cover w-full"
          autoFocus={true}
          showArrows={true}
          thumbWidth={120}
          emulateTouch
          infiniteLoop
          useKeyboardArrows={true}
          dynamicHeight={true}
          showThumbs={true}
          showIndicators={false}
        >
          {listingImages.map((imgUrl) => (
            <div key={imgUrl}>
              <img src={imgUrl} />
              {listing?.captions ? <p className="legend">Caption</p> : null}
            </div>
          )) ?? null}
        </Carousel>
        <div
          className="
            absolute
            top-5
            right-5
          "
        >
          <HeartButton listingId={id} currentUser={currentUser} />
        </div>
      </div>
    </>
  );
};

export default ListingHead;

import React, { useState } from "react";
import LogoutButton from "./LogoutButton";
import { useIntl } from "react-intl";

export default function Footer(props) {
  const intl = useIntl();
  return (
    <footer className="p-4 bg-white md:flex md:items-center md:justify-between md:p-6">
      <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
        © 2024{" "}
        <a href="https://ashtonspina.com/" className="hover:underline">
          Jobmarketplace
        </a>
        All rights reserved.
      </span>
      <ul className="flex flex-wrap items-center mt-3 text-sm text-gray-500 dark:text-gray-400 sm:mt-0">
        <li>
          <a href="#" className="mr-4 hover:underline md:mr-6 ">
            About
          </a>
        </li>
        <li>
          <a href="#" className="mr-4 hover:underline md:mr-6">
            Privacy Policy
          </a>
        </li>
        <li>
          <a href="#" className="hover:underline">
            Contact
          </a>
        </li>
        <li>
          <LogoutButton />
        </li>
      </ul>
    </footer>
  );
}

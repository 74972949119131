import * as React from "react";
import { FunctionComponent } from "react";
import { useInstantSearch } from "react-instantsearch";
import ReactGA from "react-ga4";
import { QuickSearchFacet } from "./QuickSearchFacet";
import { searchTree } from "./searchTree";
import { QuickSearchQuery } from "./QuickSearchQuery";

interface QuickSearchBannerProps {}

/* Takes-in the facet we want to set and an array of values we want to set for it and updates the search.
 * The children prop is the actual content of the button */
export const QuickSearchBanner: FunctionComponent<QuickSearchBannerProps> = (
  props
) => {
  const { indexUiState, setIndexUiState } = useInstantSearch();
  const queryExists = Object.keys(searchTree)
    .map((key) => indexUiState.query === key)
    .reduce((acc, val) => (val ? val : acc));

  return (
    <div className="hidden xl:flex flex-row gap-2 w-full pt-0 lg:pt-8 pb-4 items-center justify-center flex-nowrap overflow-x-scroll">
      <QuickSearchFacet
        facet="remoteness"
        values={["Remote"]}
        text="Remote Jobs"
      />
      <QuickSearchFacet
        facet="location"
        values={[
          "Helsinki, Finland",
          "Tampere, Finland",
          "Espoo, Finland",
          "Turku, Finland",
          "Oulu, Finland",
          "Vantaa, Finland",
        ]}
        text="Jobs in Finland"
      />
      {/*<QuickSearchFacet facet="salaryRange.min" value={["1000"]}>*/}
      {/*  <span className="rounded bg-gray-300 p-4 cursor-pointer">Jobs with Salary Info</span>*/}
      {/*</QuickSearchFacet>*/}
      <QuickSearchFacet
        facet="requiredLanguages"
        values={["English"]}
        text="Jobs that need English"
      />
      {/*<QuickSearchFacet facet="company.industry" value={["Technology"]} text="Jobs in Tech"/>*/}
      {(queryExists
        ? searchTree[indexUiState.query]
        : Object.keys(searchTree)
      ).map((key) => (
        <QuickSearchQuery key={key} text={key} />
      ))}
      {/* TODO:: Setup a way to clear refinements in each of the modals, but set translations first */}
      {/*<ClearRefinements />*/}
    </div>
  );
};

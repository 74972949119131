export const searchTree = {
  Media: [
    "Audio",
    "Podcast",
    "Producer",
    "Camera Operator",
    "Producer",
    "Director",
    "Writer",
    "Journalist",
    "Content Writer",
    "Copy Writer",
    "Translator",
  ],
  Finance: ["Accountant", "Financial Controller", "Auditor"],
  Technology: [
    "Web Engineer",
    "Test Engineer",
    "Embedded Engineer",
    "Data Engineer",
    "Machine Learning Engineer",
  ],
  Law: ["Lawyer", "Legal Consultant"],
  "Product/Design": [
    "Product Designer",
    "Product Manager",
    "Product Owner",
    "Technical Programme Manager",
  ],
  Marketing: ["Product Marketer", "SEO Manager"],
  Sales: ["Salesperson", "Account Manager"],
  "Customer Service": ["Customer Service Person"],
  Education: [
    "Pre-school Teacher",
    "Elementary Teacher",
    "High-school Teacher",
    "Professor",
  ],
  "Engineering/Construction": [
    "Mechanical Engineer",
    "Aerospace Engineer",
    "Chemical Engineer",
    "Marine Engineer",
    "Civil Engineer",
    "Architect",
  ],
  Medicine: ["Doctor", "Nurse"],
};

// TODO:: Create a tree structure that can be traversed by the function to grab all the leaves of a given leaf
export const getTreeLevel = (category: string) => {
  let level = category;
  while (searchTree[level] && typeof searchTree[level] === "object") {
    level = searchTree[level];
  }
  return searchTree[level];
};

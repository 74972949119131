import { createSlice } from "@reduxjs/toolkit";

export const traySlice = createSlice({
  name: "tray",
  initialState: {
    value: false, //is header tray open?
  },
  reducers: {
    setLang: (state, action) => {
      state.value += action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLang } = traySlice.actions;

export default traySlice.reducer;

import { FormEvent, useState } from "react";
import { uploadToS3 } from "../../../functions/upload-file";
import { useFileChange } from "../../../functions/use-file-change";
import { FormattedMessage } from "react-intl";
import * as React from "react";
import Container from "../Container";
import HeartButton from "../HeartButton";
import { MdClose } from "react-icons/md";
import { IoCloseCircleOutline } from "react-icons/io5";
import { FaUpload } from "react-icons/fa";
import { bytesToMegabytes } from "../../../functions/string-manipulation";

// TODO:: Update URL so it works
const S3_BUCKET_URL =
  "https://jobmarketplace-imagebucket97210811-mnowgoqvcwya.s3.amazonaws.com";

interface ImageUploadProps {
  onChange: (value: string[]) => void;
  value: string[];
}

interface UploadedFile {
  fileName: string;
  fileSize: number;
  fileType: string;
  s3Url: string;
}

const ImageUploader: React.FC<ImageUploadProps> = ({ onChange, value }) => {
  const { fileError, files, fileDispatch, handleFileChange } = useFileChange();
  const [uploadedFiles, setUploadedFiles] = useState<Array<UploadedFile>>([]);
  const [imageUploadProgress, setImageUploadProgress] = useState(0);
  // TODO:: Create image upload previews https://stackoverflow.com/questions/38049966/get-image-preview-before-uploading-in-react and put them here so users can manage their uploads. Then make it stateful

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      for (let i = 0; i < files.length; i++) {
        const fileType = files[i].fileType;
        const fileContents = files[i].fileContents;
        if (fileType && fileContents) {
          const filePath = await uploadToS3({ fileType, fileContents });
          const newUploadedFiles = uploadedFiles;
          newUploadedFiles.push({
            fileName: files[i].fileName,
            fileSize: files[i].fileSize,
            fileType: files[i].fileType,
            s3Url: filePath,
          });
          // TODO:: Allow user to re-order images and delete images and display them correctly on upload
          setUploadedFiles(newUploadedFiles);
          console.log("filePath is", filePath);
        }
      }
      fileDispatch({ type: "RESET_FILE_STATE" });
    } catch (err) {
      console.log("error is", err);
    }
    onChange(uploadedFiles.map((uploadedFile) => uploadedFile.s3Url));
  };
  return (
    <>
      <div className="w-full">
        <div className="flex flex-col items-center justify-center">
          {fileError && (
            <h1 className="max-w-3xl text-3xl text-red-600">{fileError}</h1>
          )}
          <div>
            <form onSubmit={handleSubmit}>
              <div className="flex items-center justify-center w-full">
                <label
                  htmlFor="picture"
                  className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                >
                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <svg
                      className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 16"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                      />
                    </svg>
                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                      <span className="font-semibold">Click to upload</span> or
                      drag and drop
                    </p>
                    <p className="text-xs text-gray-500 dark:text-gray-400">
                      PNG, JPG (MAX. 1MB)
                    </p>
                  </div>
                  <input
                    type="file"
                    accept="image/*"
                    id="picture"
                    name="picture"
                    className="hidden"
                    multiple
                    onChange={handleFileChange}
                  />
                </label>
              </div>
              {(files.length ? files : uploadedFiles).map((file, index) => (
                <div
                  key={index}
                  className="flex flex-col bg-white border shadow-sm rounded-xl dark:bg-slate-800 dark:border-gray-700"
                >
                  <div className="p-4 md:p-5 space-y-7">
                    <div>
                      <div className="mb-2 flex justify-between items-center">
                        <div className="flex items-center gap-x-3">
                          <span className="w-8 h-8 flex justify-center items-center border border-gray-200 text-gray-500 rounded-lg dark:border-neutral-700">
                            {uploadedFiles.length > index ? (
                              <img
                                key={uploadedFiles[index].s3Url}
                                src={
                                  `${S3_BUCKET_URL}/${uploadedFiles[index].s3Url}` ||
                                  ""
                                }
                                className="object-cover
                        h-full
                        w-full
                        group-hover:scale-110
                        transition h-auto max-w-sm rounded-lg shadow-none transition-shadow duration-300 ease-in-out hover:shadow-lg hover:shadow-black/30"
                                alt="Uploaded Image"
                              />
                            ) : (
                              <svg
                                className="flex-shrink-0 w-5 h-5"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
                                <polyline points="17 8 12 3 7 8" />
                                <line x1="12" x2="12" y1="3" y2="15" />
                              </svg>
                            )}
                          </span>
                          <div>
                            <p className="text-sm font-medium text-gray-800 dark:text-white">
                              {file.fileName}
                            </p>
                            <p className="text-xs text-gray-500 dark:text-gray-500 dark:text-white">
                              {bytesToMegabytes(file.fileSize)}
                            </p>
                            <p className="text-xs text-gray-500 dark:text-gray-500 dark:text-white">
                              {file.fileType}
                            </p>
                          </div>
                        </div>
                        {/* TODO:: Make the delete button actually remove the file upload */}
                        <div className="inline-flex items-center gap-x-2">
                          {uploadedFiles.length > index ? (
                            <svg
                              className="flex-shrink-0 w-4 h-4 text-teal-500"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              viewBox="0 0 16 16"
                            >
                              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                            </svg>
                          ) : null}
                          <div className="text-gray-500 hover:text-gray-800 cursor-pointer">
                            <svg
                              className="flex-shrink-0 w-4 h-4"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path d="M3 6h18" />
                              <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" />
                              <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" />
                              <line x1="10" x2="10" y1="11" y2="17" />
                              <line x1="14" x2="14" y1="11" y2="17" />
                            </svg>
                          </div>
                        </div>
                      </div>
                      {uploadedFiles.length > index ? (
                        <div className="flex items-center gap-x-3 whitespace-nowrap">
                          <div
                            className="flex w-full h-2 bg-gray-200 rounded-full overflow-hidden dark:bg-gray-700"
                            role="progressbar"
                          >
                            <div className="flex flex-col justify-center rounded-full overflow-hidden bg-teal-500 text-xs text-white text-center whitespace-nowrap transition duration-500 w-full"></div>
                          </div>
                          <div className="w-6 text-end">
                            <span className="text-sm text-gray-800 dark:text-white">
                              100%
                            </span>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="flex w-full h-2 bg-gray-200 rounded-full overflow-hidden dark:bg-gray-700"
                          role="progressbar"
                        >
                          <div className="flex flex-col justify-center rounded-full overflow-hidden bg-blue-600 text-xs text-white text-center whitespace-nowrap transition duration-500 dark:bg-blue-500"></div>
                        </div>
                      )}
                    </div>
                  </div>
                  {/*<div className="bg-gray-50 border-t border-gray-200 rounded-b-xl py-2 px-4 md:px-5 dark:bg-white/[.05] dark:border-gray-700">*/}
                  {/*  <div className="flex flex-wrap justify-between items-center gap-x-3">*/}
                  {/*    <div>*/}
                  {/*      <span className="text-sm font-semibold text-gray-800 dark:text-white">*/}
                  {/*        3 left*/}
                  {/*      </span>*/}
                  {/*    </div>*/}

                  {/*    <div className="-me-2.5">*/}
                  {/*      <button*/}
                  {/*        type="button"*/}
                  {/*        className="py-2 px-3 inline-flex items-center gap-x-1.5 text-sm font-medium rounded-lg border border-transparent text-gray-500 hover:bg-gray-200 hover:text-gray-800 disabled:opacity-50 disabled:pointer-events-none dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-white dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"*/}
                  {/*      >*/}
                  {/*        <svg*/}
                  {/*          className="flex-shrink-0 w-4 h-4"*/}
                  {/*          xmlns="http://www.w3.org/2000/svg"*/}
                  {/*          width="24"*/}
                  {/*          height="24"*/}
                  {/*          viewBox="0 0 24 24"*/}
                  {/*          fill="none"*/}
                  {/*          stroke="currentColor"*/}
                  {/*          strokeWidth="2"*/}
                  {/*          strokeLinecap="round"*/}
                  {/*          strokeLinejoin="round"*/}
                  {/*        >*/}
                  {/*          <rect width="4" height="16" x="6" y="4" />*/}
                  {/*          <rect width="4" height="16" x="14" y="4" />*/}
                  {/*        </svg>*/}
                  {/*        Pause*/}
                  {/*      </button>*/}
                  {/*      <button*/}
                  {/*        type="button"*/}
                  {/*        className="py-2 px-3 inline-flex items-center gap-x-1.5 text-sm font-medium rounded-lg border border-transparent text-gray-500 hover:bg-gray-200 hover:text-gray-800 disabled:opacity-50 disabled:pointer-events-none dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-white dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"*/}
                  {/*      >*/}
                  {/*        <svg*/}
                  {/*          className="flex-shrink-0 w-4 h-4"*/}
                  {/*          xmlns="http://www.w3.org/2000/svg"*/}
                  {/*          width="24"*/}
                  {/*          height="24"*/}
                  {/*          viewBox="0 0 24 24"*/}
                  {/*          fill="none"*/}
                  {/*          stroke="currentColor"*/}
                  {/*          strokeWidth="2"*/}
                  {/*          strokeLinecap="round"*/}
                  {/*          strokeLinejoin="round"*/}
                  {/*        >*/}
                  {/*          <path d="M3 6h18" />*/}
                  {/*          <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" />*/}
                  {/*          <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" />*/}
                  {/*          <line x1="10" x2="10" y1="11" y2="17" />*/}
                  {/*          <line x1="14" x2="14" y1="11" y2="17" />*/}
                  {/*        </svg>*/}
                  {/*        Delete*/}
                  {/*      </button>*/}
                  {/*    </div>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                </div>
              ))}
              <div className="flex flex-col items-center mt-2">
                <button
                  type="submit"
                  className="flex items-center bg-transparent hover:bg-black text-black-700 font-semibold hover:text-white py-2 pl-4 pr-4 border border-black hover:border-transparent rounded"
                >
                  <FaUpload className="mr-2" />
                  <FormattedMessage
                    id="imageUploader.uploadImagesButton"
                    description="The button to upload images"
                    defaultMessage="Upload Images"
                  />
                </button>
              </div>
            </form>
            {/*<Container>*/}
            {/*  <div*/}
            {/*    className={`pt-24*/}
            {/*grid */}
            {/*grid-cols-1 */}
            {/*sm:grid-cols-2 md:grid-cols-3*/}
            {/*gap-8`}*/}
            {/*  >*/}
            {/*    {s3FileUrls.map((s3FileUrl) => (*/}
            {/*      <div>*/}
            {/*        /!* TODO:: Amalgamate functionality with image uploads and make it possible to re-order and delete images *!/*/}
            {/*        <div*/}
            {/*          className="*/}
            {/*          top-3*/}
            {/*          right-3*/}
            {/*        "*/}
            {/*        >*/}
            {/*          <IoCloseCircleOutline />*/}
            {/*        </div>*/}
            {/*        <img*/}
            {/*          key={s3FileUrl}*/}
            {/*          src={`${S3_BUCKET_URL}/${s3FileUrl}` || ''}*/}
            {/*          className="object-cover*/}
            {/*            h-full*/}
            {/*            w-full*/}
            {/*            group-hover:scale-110*/}
            {/*            transition h-auto max-w-sm rounded-lg shadow-none transition-shadow duration-300 ease-in-out hover:shadow-lg hover:shadow-black/30"*/}
            {/*          alt="Uploaded Image"*/}
            {/*        />*/}
            {/*      </div>*/}
            {/*    ))}*/}
            {/*  </div>*/}
            {/*</Container>*/}
          </div>
        </div>
      </div>
    </>
  );
};

export default ImageUploader;

import Modal from "./Modal";
import Heading from "../Heading";
import useCategoryBoxModal from "../../hooks/useCategoryBoxModal";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import UniversalInput from "../inputs/UniversalInput";
import {
  extractLivingAreaRangeFromParams,
  extractPriceRangeFromParams,
} from "../../../functions/url-search-params";

const CategoryBoxModal = (label) => {
  const onSubmit = () => {};
  const categoryBoxModal = useCategoryBoxModal();
  const [isLoading, setIsLoading] = useState(false);
  const [categoryErrors, setCategoryErrors] = useState({});

  const [searchParams, setSearchParams] = useSearchParams();
  // single-time read
  let params = Object.fromEntries(searchParams);
  useEffect(() => {
    params = Object.fromEntries(searchParams);
  }, [searchParams]);

  const bodyContent = (
    <>
      <Heading
        title="Set the Search Category Value"
        subtitle="Choose the value"
      />
      <UniversalInput
        id="category-box-input-rental-type"
        label={"Rental Type"}
        errors={categoryErrors}
        type={"select"}
        selectOptions={{
          Apartment: "apartment rental",
          House: "house rental",
          Room: "room rental",
        }}
        onChange={(e) => {
          const newParamsObject = { ...params };
          newParamsObject["Type"] = e.target.value;
          setSearchParams(newParamsObject);
        }}
      />
      <UniversalInput
        id="category-box-input-rooms"
        label={"Rooms"}
        subLabel={"The minimum number of rooms"}
        errors={categoryErrors}
        type={"number"}
        value={parseInt(params["Rooms"]) ? parseInt(params["Rooms"]) : 1}
        onChange={(changedValue: string) => {
          const newParamsObject = { ...params };
          newParamsObject["Rooms"] = changedValue;
          setSearchParams(newParamsObject);
        }}
      />
      <hr className="my-10" />
      <UniversalInput
        id="category-box-input-price-range-min"
        label={"Price Min."}
        errors={categoryErrors}
        type={"range"}
        rangeType={"money"}
        value={extractPriceRangeFromParams(searchParams)[0]}
        onChange={(e) => {
          const newParamsObject = { ...params };
          newParamsObject["Price Range"] = `${e.target.value}-${
            extractPriceRangeFromParams(searchParams)[1]
          }`;
          setSearchParams(newParamsObject);
        }}
      />
      <div className="mt-8">
        <UniversalInput
          id="category-box-input-price-range-max"
          label={"Price Max."}
          errors={categoryErrors}
          type={"range"}
          rangeType={"money"}
          value={extractPriceRangeFromParams(searchParams)[1]}
          onChange={(e) => {
            const newParamsObject = { ...params };
            newParamsObject["Price Range"] = `${
              extractPriceRangeFromParams(searchParams)[0]
            }-${e.target.value}`;
            setSearchParams(newParamsObject);
          }}
        />
      </div>
      <hr className="my-10" />
      <UniversalInput
        id="category-box-input-living-space-min"
        label={"Living Area Min."}
        errors={categoryErrors}
        type={"range"}
        rangeType={"living_area"}
        value={extractLivingAreaRangeFromParams(searchParams)[0]}
        onChange={(e) => {
          const newParamsObject = { ...params };
          newParamsObject["Living Area"] = `${e.target.value}-${
            extractLivingAreaRangeFromParams(searchParams)[1]
          }`;
          setSearchParams(newParamsObject);
        }}
      />
      <div className="mt-8">
        <UniversalInput
          id="category-box-input-living-space-max"
          label={"Living Area Max."}
          errors={categoryErrors}
          type={"range"}
          rangeType={"living_area"}
          value={extractLivingAreaRangeFromParams(searchParams)[1]}
          onChange={(e) => {
            const newParamsObject = { ...params };
            newParamsObject["Living Area"] = `${
              extractLivingAreaRangeFromParams(searchParams)[0]
            }-${e.target.value}`;
            setSearchParams(newParamsObject);
          }}
        />
      </div>
      <hr className="my-10" />
      <UniversalInput
        id="category-box-input-furnished"
        label={"Furnished"}
        errors={categoryErrors}
        type={"checkbox"}
        onChange={(e) => {
          const newParamsObject = { ...params };
          newParamsObject["Furnished"] = e.target.checked;
          setSearchParams(newParamsObject);
        }}
      />
      <UniversalInput
        id="category-box-input-balcony"
        label={"Balcony"}
        errors={categoryErrors}
        type={"checkbox"}
        value={params["Balcony"]}
        onChange={(e) => {
          const newParamsObject = { ...params };
          newParamsObject["Balcony"] = e.target.checked;
          setSearchParams(newParamsObject);
        }}
      />
      <UniversalInput
        id="category-box-input-fireplace"
        label={"Fireplace"}
        errors={categoryErrors}
        type={"checkbox"}
        onChange={(e) => {
          const newParamsObject = { ...params };
          newParamsObject["Fireplace"] = e.target.checked;
          setSearchParams(newParamsObject);
        }}
      />
      <UniversalInput
        id="category-box-input-elevator"
        label={"Elevator"}
        errors={categoryErrors}
        type={"checkbox"}
        onChange={(e) => {
          const newParamsObject = { ...params };
          newParamsObject["Elevator"] = e.target.checked;
          setSearchParams(newParamsObject);
        }}
      />
      <UniversalInput
        id="category-box-input-pets"
        label={"Pets Allowed"}
        errors={categoryErrors}
        type={"checkbox"}
        onChange={(e) => {
          const newParamsObject = { ...params };
          newParamsObject["Pets Allowed"] = e.target.checked;
          setSearchParams(newParamsObject);
        }}
      />
      <UniversalInput
        id="category-box-input-parking"
        label={"Parking Space"}
        errors={categoryErrors}
        type={"checkbox"}
        onChange={(e) => {
          const newParamsObject = { ...params };
          newParamsObject["Parking Space"] = e.target.checked;
          setSearchParams(newParamsObject);
        }}
      />
    </>
  );

  return (
    <Modal
      isOpen={categoryBoxModal.isOpen}
      title="Search Categories"
      actionLabel="Save"
      onClose={categoryBoxModal.onClose}
      onSubmit={categoryBoxModal.onClose}
      body={bodyContent}
    />
  );
};

export default CategoryBoxModal;

"use client";

import { useEffect, useState } from "react";
import useSearchModal from "../../hooks/useSearchModal";
import Modal from "./Modal";
import Heading from "../Heading";
import { useSearchParams } from "react-router-dom";
import { MdLocalMall, MdOutlineTram } from "react-icons/md";
import { FaTrainSubway } from "react-icons/fa6";
import { IoTrain } from "react-icons/io5";
import geoLocations from "../../../data/geoLocations.json";
import { diceCoefficient } from "dice-coefficient";
import * as React from "react";
import { SearchCodeFeature, SearchCodes } from "../../../types";
import useCategoryBoxModal from "../../hooks/useCategoryBoxModal";
import SearchResultsListItem from "../SearchResultsListItem";
const SearchModal = () => {
  const [geoQuery, setGeoQuery] = useState("");
  const [geoData, setGeoData] = useState([]);

  const stringToNormalForm = (str: String) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  const searchValueChange = (event) => {
    setGeoQuery(event.target.value);
    let diceCoefficientValuesList = [];
    // Use dice-sorensen algorithm to compare similarity of search string to all district names
    geoLocations.features.forEach((feature) => {
      // remove special characters to make typing and comparing easier with all accented characters
      diceCoefficientValuesList.push([
        diceCoefficient(
          stringToNormalForm(event.target.value),
          stringToNormalForm(feature.properties.name)
        ),
        feature,
      ]);
    });
    // sort based on similarity score
    diceCoefficientValuesList = diceCoefficientValuesList.sort((a, b) => {
      return b[0] - a[0];
    });
    // take the first 5 most similar features
    const filteredData = diceCoefficientValuesList
      .slice(0, 5)
      .map((pair) => pair[1]);
    setGeoData(filteredData);
  };

  const [searchParams, setSearchParams] = useSearchParams();

  const updateSearchParamsFromSearchCodes = (searchCodes: SearchCodes) => {
    // TODO:: Delete the object from the URLSearchParams entirely if it's empty
    if (searchCodes.features.length === 0) {
      searchParams.delete("searchCodes");
      const newSearchParams = new URLSearchParams(searchParams);
      setSearchParams(newSearchParams);
    } else {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set("searchCodes", JSON.stringify(searchCodes));
      setSearchParams(newSearchParams);
    }
  };
  const updateSearchCodesFromSearchParams = (searchParams: URLSearchParams) => {
    const searchCodesString = searchParams.get("searchCodes");
    if (searchCodesString) {
      setSearchCodes(JSON.parse(searchCodesString));
    }
  };
  useEffect(() => {
    updateSearchCodesFromSearchParams(searchParams);
  }, [searchParams]);
  const [searchCodes, setSearchCodes] = useState({
    type: "FeatureCollection",
    features: [],
  });

  const filteredGeoData: SearchCodeFeature[] = geoData.filter(
    (geoFeature: SearchCodeFeature) => {
      let equalFound = true;
      searchCodes.features.forEach((searchFeature) => {
        if (
          stringToNormalForm(geoFeature.properties.name) ===
          stringToNormalForm(searchFeature.properties.name)
        ) {
          equalFound = false;
        }
      });
      return equalFound;
    }
  );

  const searchModal = useSearchModal();
  const categoryBoxModal = useCategoryBoxModal();

  const actionLabel = "Search";
  const secondaryActionLabel = "Filters";

  let bodyContent = (
    <div className="flex flex-col gap-8">
      <Heading
        title="Where do do you want to live?"
        subtitle="Search by Helsinki Neighbourhoods"
      />

      <div className="relative">
        <div className="absolute flex items-center ml-2 h-full">
          <svg
            className="w-4 h-4 fill-current text-primary-gray-dark"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M15.8898 15.0493L11.8588 11.0182C11.7869 10.9463 11.6932 10.9088 11.5932 10.9088H11.2713C12.3431 9.74952 12.9994 8.20272 12.9994 6.49968C12.9994 2.90923 10.0901 0 6.49968 0C2.90923 0 0 2.90923 0 6.49968C0 10.0901 2.90923 12.9994 6.49968 12.9994C8.20272 12.9994 9.74952 12.3431 10.9088 11.2744V11.5932C10.9088 11.6932 10.9495 11.7869 11.0182 11.8588L15.0493 15.8898C15.1961 16.0367 15.4336 16.0367 15.5805 15.8898L15.8898 15.5805C16.0367 15.4336 16.0367 15.1961 15.8898 15.0493ZM6.49968 11.9994C3.45921 11.9994 0.999951 9.54016 0.999951 6.49968C0.999951 3.45921 3.45921 0.999951 6.49968 0.999951C9.54016 0.999951 11.9994 3.45921 11.9994 6.49968C11.9994 9.54016 9.54016 11.9994 6.49968 11.9994Z"></path>
          </svg>
        </div>

        <input
          type="search"
          placeholder="Search by Helsinki neighbourhoods"
          className="px-8 py-3 w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 text-sm"
          value={geoQuery}
          onChange={(event) => searchValueChange(event)}
        />
      </div>
      <div
        id="searchResults"
        className="w-full max-w-sm bg-white rounded divide-y divide-gray-100 shadow"
      >
        <ul className="max-w-md divide-gray-200 dark:divide-gray-700">
          {searchCodes.features.map((geoItem: any) => (
            <SearchResultsListItem
              key={geoItem.properties.name}
              onClick={() => {
                const index = searchCodes.features.findIndex((i) => {
                  return i.properties.name === geoItem.properties.name;
                });
                const newSearchCodes: SearchCodes = { ...searchCodes };
                newSearchCodes.features.splice(index, 1);
                updateSearchParamsFromSearchCodes(newSearchCodes);
              }}
              geoItem={geoItem}
              highlighted={true}
            />
          ))}
          {filteredGeoData.map((geoItem: any) => (
            <SearchResultsListItem
              key={geoItem.properties.name}
              onClick={() => {
                const newSearchCodes: SearchCodes = { ...searchCodes };
                newSearchCodes.features.push(geoItem);
                updateSearchParamsFromSearchCodes(newSearchCodes);
              }}
              geoItem={geoItem}
              highlighted={false}
            />
          ))}
        </ul>
      </div>
    </div>
  );

  return (
    <Modal
      isOpen={searchModal.isOpen}
      title="Filters"
      actionLabel={actionLabel}
      onSubmit={searchModal.onClose}
      secondaryActionLabel={secondaryActionLabel}
      secondaryAction={() => {
        searchModal.onClose();
        categoryBoxModal.onOpen();
      }}
      onClose={searchModal.onClose}
      body={bodyContent}
    />
  );
};

export default SearchModal;

import { useNavigate } from "react-router-dom";
import { localizedRouteGenerator } from "../../../languages/routes/routeGenerator";
import { useIntl } from "react-intl";

const Logo = () => {
  const navigate = useNavigate();
  const intl = useIntl();

  return (
    <>
      <img
        onClick={() => navigate(localizedRouteGenerator(intl, "route.home"))}
        className="hidden sm:block cursor-pointer"
        src={"/logo_horizontal.png"}
        height="100"
        width="100"
        alt="Logo"
      />
    </>
  );
};

export default Logo;
